<template>
  <MainLayout>
    <template v-slot:navbar-title>
      <!--      {{ `${lastName}  ${name}  ${middleName}` }}-->
    </template>
    <CreateFormLayout>
      <template v-slot:title__text>
        Опекун
      </template>
      <template v-slot:title-flex-right>
        <div></div>
      </template>
      <template>
        <!--    Данные компании    -->
        <template>
          <!--    Данные компании    -->
          <div>
            <FormCardTitle>Личные данные</FormCardTitle>
            <FormInputBlock>
              <ValidationInputField
                label="Телефон"
                :disabled="
                  (currentRole == 70 &&
                    is_phone_confirmed == false &&
                    is_phone_confirmed == true) ||
                    (currentRole == 31 && is_phone_confirmed == true) ||
                    (currentRole == 60 && is_phone_confirmed == true)
                "
                rules="phone|required"
                v-model="phoneParents"
                validate-name="Телефон"
              ></ValidationInputField>

              <ValidationInputField
                label="Пользователь (необязательно)"
                validate-name="пользователь"
                :rules="'min:1|max:40'"
                v-model="user"
              />
              <ValidationInputField
                label="Комментарий (необязательно)"
                validate-name="комментарий"
                :rules="'min:1|max:40'"
                v-model="comments"
              />
            </FormInputBlock>
          </div>
          <!--    Данные компании    -->
        </template>
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(editParent)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import { getIdentificatorUserRole } from "../../helpers/Constants/roles";
export default {
  name: "EditParent",
  components: {
    ValidationInputField,
    FormInputBlock,
    FormCardTitle,
    CreateFormLayout,
    MainLayout
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },

    is_phone_confirmed: {
      get() {
        return this.$store.getters.getEditParent.is_phone_confirmed.value;
      }
    },

    phoneParents: {
      get() {
        return this.$store.getters.getEditParent.phone_number.value;
      },
      set(newValue) {
        this.$store.commit("changeEditParent", {
          fieldName: "phone_number",
          value: newValue
        });
      }
    },

    user: {
      get() {
        return this.$store.getters.getEditParent.user.value;
      },
      set(newValue) {
        this.$store.commit("changeEditParent", {
          fieldName: "user",
          value: newValue,
          id: localStorage.getItem("UserFieldId")
        });
      }
    },
    comments: {
      get() {
        return this.$store.getters.getEditParent.comments.value;
      },
      set(newValue) {
        this.$store.commit("changeEditParent", {
          fieldName: "comments",
          value: newValue,
          id: localStorage.getItem("CommentsFieldId")
        });
      }
    }
  },
  created() {
    this.$store.dispatch("prepareEditParent", {
      id: this.$route.params.id,
      kinderId: this.$route.params.kindergartenId
    });
  },
  methods: {
    editParent() {
      this.loading = true;
      this.$store
        .dispatch("editParent", {
          id: this.$route.params.id,
          kinderId: getIdentificatorUserRole()
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.parent)
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
